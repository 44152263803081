/* You can add global styles to this file, and also import other style files */
$utilities: () !default;

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
);

$utilities: map-merge(
  $utilities,
  (
    "cursor": (
      property: cursor,
      class: cursor,
      responsive: true,
      values: auto pointer grab,
    ),
    "margin": (
      responsive: true,
      property: margin,
      class: m,
      values:
        map-merge(
          $spacers,
          (
            auto: auto,
          )
        ),
    ),
    "margin-x": (
      responsive: true,
      property: margin-right margin-left,
      class: mx,
      values:
        map-merge(
          $spacers,
          (
            auto: auto,
          )
        ),
    ),
    "margin-y": (
      responsive: true,
      property: margin-top margin-bottom,
      class: my,
      values:
        map-merge(
          $spacers,
          (
            auto: auto,
          )
        ),
    ),
    "margin-top": (
      responsive: true,
      property: margin-top,
      class: mt,
      values:
        map-merge(
          $spacers,
          (
            auto: auto,
          )
        ),
    ),
    "margin-end": (
      responsive: true,
      property: margin-right,
      class: me,
      values:
        map-merge(
          $spacers,
          (
            auto: auto,
          )
        ),
    ),
    "margin-bottom": (
      responsive: true,
      property: margin-bottom,
      class: mb,
      values:
        map-merge(
          $spacers,
          (
            auto: auto,
          )
        ),
    ),
    "margin-start": (
      responsive: true,
      property: margin-left,
      class: ms,
      values:
        map-merge(
          $spacers,
          (
            auto: auto,
          )
        ),
    ),
    // Padding utilities
    "padding":
      (
        responsive: true,
        property: padding,
        class: p,
        values: $spacers,
      ),
    "padding-x": (
      responsive: true,
      property: padding-right padding-left,
      class: px,
      values: $spacers,
    ),
    "padding-y": (
      responsive: true,
      property: padding-top padding-bottom,
      class: py,
      values: $spacers,
    ),
    "padding-top": (
      responsive: true,
      property: padding-top,
      class: pt,
      values: $spacers,
    ),
    "padding-end": (
      responsive: true,
      property: padding-right,
      class: pe,
      values: $spacers,
    ),
    "padding-bottom": (
      responsive: true,
      property: padding-bottom,
      class: pb,
      values: $spacers,
    ),
    "padding-start": (
      responsive: true,
      property: padding-left,
      class: ps,
      values: $spacers,
    ),
  )
);

$linkColor: #2362be;
