.rv-icon-feather-globe {
  width: 18px;
  height: 18px;
  background-image: url("/assets/icons/rv-icon-feather-globe.svg");
  display: inline-block;
}

.rv-icon-eye {
  width: 22px;
  height: 22px;
  background-image: url("/assets/icons/rv-icon-eye.svg");
  display: inline-block;
}

.rv-icon-eye-line {
  height: 20px;
  width: 1px;
  position: absolute;
  top: 3px;
  right: 9px;
  background-color: #ffffff;
  transform: rotate(56deg);
}

.rv-icon-circle-check {
  width: 16px;
  height: 16px;
  background-image: url("/assets/icons/rv-icon-circle-check.svg");
  display: inline-block;
}

.rv-icon-icon-up, .rv-icon-icon-down {
  width: 24px;
  height: 24px;
  background-image: url("/assets/icons/rv-icon-icon-down.svg");
  display: inline-block;
}

.rv-icon-icon-up {
  transform: rotate(180deg);
}

.rv-icon-close {
  width: 24px;
  height: 24px;
  background-image: url("/assets/icons/rv-icon-close.svg");
  background-size: cover;
  display: inline-block;
}

.rv-icon-menu {
  width: 24px;
  height: 18px;
  background-image: url("/assets/icons/rv-icon-menu.svg");
  display: inline-block;
}

.rv-icon-back {
  width: 10px;
  height: 20px;
  background-image: url("/assets/icons/rv-icon-back.svg");
  display: inline-block;
}

.rv-icon-plus {
  width: 16px;
  height: 16px;
  background-image: url("/assets/icons/rv-icon-plus.svg");
  display: inline-block;
}

.rv-icon-minus {
  width: 16px;
  height: 16px;
  background-image: url("/assets/icons/rv-icon-minus.svg");
  display: inline-block;
}

.rv-icon-send {
  width: 16px;
  height: 16px;
  background-image: url("/assets/icons/rv-icon-send.svg");
  display: inline-block;
}

.rv-icon-envelope {
  width: 16px;
  height: 16px;
  background-image: url("/assets/icons/rv-icon-envelope.svg");
  display: inline-block;
}

.rv-icon-dropdown {
  width: 18px;
  height: 18px;
  background-image: url("/assets/icons/rv-icon-dropdown.svg");
  display: inline-block;
}

.rv-icon-dropdown-active {
  width: 18px;
  height: 18px;
  background-image: url("/assets/icons/rv-icon-dropdown-active.svg");
  display: inline-block;
}

.rv-icon-folder {
  width: 18px;
  height: 18px;
  background-image: url("/assets/icons/rv-icon-folder.svg");
  display: inline-block;
}

.rv-icon-play {
  width: 18px;
  height: 18px;
  background-image: url("/assets/icons/rv-icon-play.svg");
  display: inline-block;
}

.light-icon {
  filter: brightness(0) invert(1);
}

.light-blue-icon {
  filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(190deg)
    brightness(119%) contrast(100%);
}
