@import "./variables";
@import "../node_modules/bootstrap/scss/bootstrap";
@import "./styles.icon.scss";
@import "../node_modules/ngx-spinner/animations/ball-spin-clockwise.css";
@import 'ngx-toastr/toastr';

@font-face {
  font-family: "nunito";
  src: url(assets/fonts/nunito/Nunito-VariableFont_wght.ttf);
}

:root {
  --bs-body-font-family: "nunito" !important;
}

.page-title {
  color: #ffffff;
  font-size: 24px;
  font-weight: 300;
}

.btn-rv {
  background-color: $linkColor !important;
}

.btn-rv-cancel {
  background-color: #253858 !important;
  border-color: #253858 !important;
}

.error-message {
  color: #f14633;
  font-weight: 700;
  margin-top: 5px;
  font-size: 14px;
  padding-left: 15px;
}

// Override modal style
.modal {
  text-align: center !important;

  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
}

.modal-dialog {
  display: inline-block;
  vertical-align: middle;
}

.modal-header {
  border-bottom: none !important;
}

.modal-footer {
  border-top: none !important;
}

.modal-open {
  padding-right: 0 !important;
}

.bg-content-img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center; /* or background-position: 0 0; */
  background-image: url("/assets/images/introduction-background.JPG");
}

.world-map {
  background-image: url("/assets/images/world-map-bg.png");
}

.one-trust-logo {
  height: 18px;
}

.toast-bottom-center{
  width: 100%!important;
}

.toast-bottom-center > .toast-error, .toast-info, .toast-success, .toast-warning {
  width: fit-content!important;
}

.toast-error, .toast-info, .toast-success, .toast-warning {
  background-image: unset!important;
}

.toast-error {  
  background-color: #FF0000;
}

.toast-info {
  background-color: #0045AC;
}

.toast-success {
  background-color: #008131;
}

.toast-warning {
  background-color: #D07000;
}

.toast-message {
  text-align: center;
}

.toast-container {
  z-index: 999999 !important;

  .ngx-toastr {
    box-shadow: unset;
    border-radius: 10px;
    margin-bottom: 95px;
    padding: 15px;
  }
}

/* Pagination */
.pagination-margin {
  text-align: center;
}

.pagination-margin .ngx-pagination {
  padding: 0;

  a {
    text-decoration: none;
    color: $linkColor !important;
  }

  .pagination-previous {
    a:before {
      content: '\003C';
    }

    &.disabled::before {
      content: '\003C';
    }
  }

  .pagination-next {
    a:after {
      content: '\003E';
    }

    &.disabled:after {
      content: '\003E' 
    }
  }
}
